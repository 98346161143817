// noinspection ES6CheckImport
import React from 'react'
import platformLogoBehance from '../../assets/images/platform-logo-behance.svg'
import platformLogoDribble from '../../assets/images/platform-logo-dribble.svg'
import platformLogoLinkedin from '../../assets/images/platform-logo-linkedin.svg'
import './media-platforms.scss'

const MediaPlatforms = () => {
    return (
        <ul className="media-patforms">
            <li>
                <a
                    href="https://www.behance.net/southlane"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={platformLogoBehance} alt="Behance" />
                </a>
            </li>
            <li>
                <a
                    href="https://dribbble.com/southlane"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={platformLogoDribble} alt="Dribble" />
                </a>
            </li>
            <li>
                <a
                    href="https://www.linkedin.com/company/southlane"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={platformLogoLinkedin} alt="Linkedin" />
                </a>
            </li>
        </ul>
    )
}

export default MediaPlatforms
