import React from 'react'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import Button from '../button/button'
import MediaPlatforms from '../media-platforms/media-platforms'
import logo from '../../assets/images/logo.svg'
import './navbar.scss'

const Navbar = ({ setMenuOpened }) => {
    return (
        <Headroom>
            <nav className="navbar">
                <div className="navbar__content width-container">
                    <Link to="/" className="logo-link">
                        <img src={logo} className="logo" alt="South Lane" />
                    </Link>

                    <label
                        htmlFor="menu-main-trigger-input"
                        className="menu-main-trigger"
                    >
                        <i className="menu-main-trigger__ico" />
                        Open mobile menu
                    </label>

                    <Location>
                        {({ location }) => {
                            return (
                                <ul className="menu-main">
                                    <li
                                        className={`${
                                            location.pathname === '/services'
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to="/services"
                                            onClick={() => setMenuOpened(false)}
                                        >
                                            Services
                                        </Link>
                                    </li>
                                    <li
                                        className={`${
                                            location.pathname === '/about'
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to="/about"
                                            onClick={() => setMenuOpened(false)}
                                        >
                                            About
                                        </Link>
                                    </li>
                                    <li
                                        className={`${
                                            location.pathname === '/careers'
                                                ? 'current'
                                                : ''
                                        }`}
                                    >
                                        <Link
                                            to="/careers"
                                            onClick={() => setMenuOpened(false)}
                                        >
                                            Careers
                                        </Link>
                                    </li>
                                    <li className="hidden-mobile-up">
                                        <Link
                                            to="/contact"
                                            onClick={() => setMenuOpened(false)}
                                        >
                                            Contact
                                        </Link>
                                    </li>
                                    <li className="hidden-mobile-up">
                                        <Link
                                            to="/privacy-policy"
                                            onClick={() => setMenuOpened(false)}
                                        >
                                            Privacy
                                        </Link>
                                    </li>
                                    <li className="hidden-mobile-down">
                                        <Button
                                            href="/contact"
                                            onClick={() => setMenuOpened(false)}
                                            text="Contact"
                                            className="button--l"
                                        />
                                    </li>
                                    <li className="hidden-mobile-up">
                                        <MediaPlatforms />
                                    </li>
                                </ul>
                            )
                        }}
                    </Location>
                </div>
            </nav>
        </Headroom>
    )
}

export default Navbar

Navbar.propTypes = {
    setMenuOpened: PropTypes.func,
}

Navbar.defaultProps = {
    setMenuOpened: null,
}
