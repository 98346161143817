import React, { useState, useEffect } from 'react'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import Navbar from './navbar/navbar'
import Footer from './footer/footer'
import '../assets/scss/layout.scss'
import Cursor from '../util/cursor'

const Layout = ({ children, isContacts }) => {
    useEffect(() => {
        const cursor = new Cursor()
        cursor.init()

        return cursor.cleanup.bind(cursor)
    }, [])

    const [isMenuOpened, setMenuOpened] = useState(false)

    return (
        <div className="page-wrapper">
            <input
                type="checkbox"
                id="menu-main-trigger-input"
                checked={isMenuOpened}
                onChange={() => setMenuOpened(!isMenuOpened)}
            />
            <Navbar setMenuOpened={setMenuOpened} />
            <div className="page-main width-container">
                <main className="width-limiter">{children}</main>
            </div>
            <Footer isContacts={isContacts} />
        </div>
    )
}

export default Layout

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    isContacts: PropTypes.bool,
}

Layout.defaultProps = {
    isContacts: false,
}
