const OUTLINE_DELAY = 4

class Cursor {
    constructor() {
        this.dot = document.querySelector('.cursor')
        this.dotX = undefined
        this.dotY = undefined
        this.outline = document.querySelector('.cursor-outline')
        this.outlineX = undefined
        this.outlineY = undefined
        this.started = false
        this.eventListenerCleaners = []
        this.requestAnimationFrameId = undefined
    }

    init() {
        this.setupEventListeners()
        this.animateOutline()
    }

    cleanup() {
        this.eventListenerCleaners.forEach(cleaner => cleaner())
        cancelAnimationFrame(this.requestAnimationFrameId)
    }

    addEventListener(scope, type, handler) {
        scope.addEventListener(type, handler)
        this.eventListenerCleaners.push(() => {
            scope.removeEventListener(type, handler)
        })
    }

    setupEventListeners() {
        const clickableTags = ['a', 'button', 'input', 'textarea', 'label']

        clickableTags.forEach(tag => {
            document.querySelectorAll(tag).forEach(el => {
                this.addEventListener(el, 'mouseover', this.enlarge.bind(this))
                this.addEventListener(el, 'mouseout', this.reduce.bind(this))
            })
        })

        this.addEventListener(document, 'mousemove', e => {
            this.started = true

            this.dotX = e.clientX
            this.dotY = e.clientY
            this.dot.style.left = `${this.dotX}px`
            this.dot.style.top = `${this.dotY}px`
            // this.dot.style.transform = `translate(-50%, -50%) matrix(1, 0, 0, 1, ${this.dotX}, ${this.dotY}`

            if (this.outlineX === undefined) {
                this.outlineX = this.dotX
                this.outlineY = this.dotY
            }

            this.show()
        })

        this.addEventListener(document.body, 'mouseleave', this.hide.bind(this))

        this.addEventListener(document, 'touchstart', () => {
            this.hide()
            this.cleanup()
            document.body.className = document.body.className.replace(
                /\bcustom-cursor\b/,
                ''
            )
        })
    }

    animateOutline() {
        if (this.started) {
            this.outlineX += (this.dotX - this.outlineX) / OUTLINE_DELAY
            this.outlineY += (this.dotY - this.outlineY) / OUTLINE_DELAY
            this.outline.style.left = `${this.outlineX}px`
            this.outline.style.top = `${this.outlineY}px`
            // this.outline.style.transform = `translate(-50%, -50%) matrix(${
            //     this.isLarge ? 1.5 : 1
            // }, 0, 0, ${this.isLarge ? 1.5 : 1}, ${this.outlineX}, ${
            //     this.outlineY
            // }`
        }

        this.requestAnimationFrameId = requestAnimationFrame(
            this.animateOutline.bind(this)
        )
    }

    enlarge() {
        this.outline.style.transform = 'translate(-50%, -50%) scale(1.4)'
    }

    reduce() {
        this.outline.style.transform = 'translate(-50%, -50%) scale(1)'
    }

    show() {
        this.dot.style.opacity = 1
        this.outline.style.opacity = 1
    }

    hide() {
        this.dot.style.opacity = 0
        this.outline.style.opacity = 0
    }
}

export default Cursor
