import React from 'react'
import Helmet from 'react-helmet'
// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Facebook from './facebook'
import Twitter from './twitter'

const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
                defaultTitle: title
                defaultDescription: description
                defaultImage: image
                siteLanguage
                ogLanguage
                twitterUsername
                facebookSiteName
            }
        }
    }
`

const Seo = ({ title, desc, image, pathname }) => {
    const { site } = useStaticQuery(query)

    const {
        siteMetadata: {
            siteUrl,
            defaultTitle,
            defaultDescription,
            defaultImage,
            siteLanguage,
            ogLanguage,
            twitterUsername,
            facebookSiteName,
        },
    } = site

    const seo = {
        title: title || defaultTitle,
        description: desc || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || ''}`,
    }

    // schema.org in JSONLD format
    // https://developers.google.com/search/docs/guides/intro-structured-data
    // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

    const schemaOrgWebPage = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: siteUrl,
        name: defaultTitle,
        logo: `${siteUrl}${defaultImage}`,
    }

    return (
        <>
            <Helmet title={seo.title}>
                <html lang={siteLanguage} />
                <meta name="description" content={seo.description} />
                <meta name="image" content={seo.image} />
                <script type="application/ld+json">
                    {JSON.stringify(schemaOrgWebPage)}
                </script>
            </Helmet>
            <Facebook
                desc={seo.description}
                image={seo.image}
                title={seo.title}
                type="website"
                url={seo.url}
                locale={ogLanguage}
                name={facebookSiteName}
            />
            <Twitter
                title={seo.title}
                image={seo.image}
                desc={seo.description}
                username={twitterUsername}
            />
        </>
    )
}

export default Seo

Seo.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    image: PropTypes.string,
    pathname: PropTypes.string,
}

Seo.defaultProps = {
    title: null,
    desc: null,
    image: null,
    pathname: null,
}
