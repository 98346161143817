// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import React from 'react'
import SVG from 'react-inlinesvg'
import IcoArrow from '../../assets/images/ico-arrow.svg'
import './button.scss'

const Button = ({ text, href, type, disabled, className, onClick }) => {
    const Icon = () => <SVG src={IcoArrow} className="svg-ico" />

    return type ? (
        // eslint-disable-next-line react/button-has-type
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`button ${className || ''}`}
        >
            <span>{text}</span>
            <Icon />
        </button>
    ) : (
        <Link to={href} className={`button ${className || ''}`}>
            <span>{text}</span>
            <Icon />
        </Link>
    )
}

export default Button

Button.propTypes = {
    text: PropTypes.string.isRequired,
    href: PropTypes.string,
    type: PropTypes.oneOf(['submit', 'button', 'reset', '']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
}

Button.defaultProps = {
    href: '',
    type: '',
    disabled: false,
    className: '',
    onClick: null,
}
