// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import Button from '../button/button'
import MediaPlatforms from '../media-platforms/media-platforms'
import './footer.scss'

const Footer = ({ isContacts }) => {
    return (
        <footer className={`footer ${isContacts ? 'footer--contacts' : ''}`}>
            <div className="width-container">
                <div className="width-limiter">
                    {!isContacts && (
                        <div className="footer-top">
                            <div className="footer-top__part">
                                <div className="footer-top__heading">
                                    We’d love to chat
                                </div>
                                <p>
                                    <Button
                                        text="Get in touch"
                                        href="/contact"
                                        className="button--l-mobile"
                                    />
                                </p>
                            </div>

                            <div className="footer-top__part footer-menu">
                                <nav className="menu-simple">
                                    <div className="menu-simple__heading">
                                        Services
                                    </div>
                                    <ul>
                                        <li>
                                            <Link to="/services#strategy">
                                                Strategy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#ideation">
                                                Ideation and Concept
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#branding">
                                                Branding
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#content">
                                                Content
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#ux">
                                                User Experience Design
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#ui">
                                                User Interface Design
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#web">
                                                Web Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#mobile">
                                                Mobile Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#pm">
                                                Project Management
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/services#qa">
                                                Quality Assurance
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                                <nav className="menu-simple">
                                    <div className="menu-simple__heading">
                                        Company
                                    </div>
                                    <ul>
                                        <li>
                                            <Link to="/about">About</Link>
                                        </li>
                                        <li>
                                            <Link to="/careers">Careers</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    )}

                    {isContacts && (
                        <div className="footer-top">
                            <div className="footer-top__part">
                                <p>
                                    <strong>South Lane Pte. Ltd.</strong>
                                </p>
                                <p>
                                    160 Robinson Road #14-04
                                    <br />
                                    Singapore Business Federation Centre
                                    <br />
                                    Singapore 068914
                                    <br />
                                    <br />
                                    Reg No: 201919837M
                                </p>
                            </div>

                            <div className="footer-top__part footer-contacts-list">
                                <div className="footer-contacts-list__item">
                                    <p>
                                        <strong>Email us</strong>
                                    </p>
                                    <p>
                                        <strong>
                                            <a href="mailto:hello@southlane.com">
                                                hello@southlane.com
                                            </a>
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="footer-bottom">
                        <div className="footer-bottom__part">
                            Copyright &copy; South Lane Pte. Ltd. All Rights
                            Reserved.
                        </div>

                        <div className="footer-bottom__part">
                            <MediaPlatforms />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer

Footer.propTypes = {
    isContacts: PropTypes.bool,
}

Footer.defaultProps = {
    isContacts: false,
}
